import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { ToastService } from '../../services/toast/toast.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService implements HttpInterceptor {

  constructor(
    public auth: AuthService,
    public toast: ToastService,
    public router: Router
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError((err: HttpErrorResponse) => {
        let attempts = 0;
        const maxAttempts = 2;

        if (err.status === 401 && !err.url.includes('oauth/token') && err.message === 'Unauthenticated.') {
          if (attempts > maxAttempts) {
            this.handleFailure(err);
            return throwError(err);
          }

          return this.auth.refreshToken()
            .pipe(catchError((e) => {
              this.handleFailure(err);
              return throwError(e);
            }))
            .pipe(switchMap((token) => {
              attempts++;
              const newRequest = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${token.access_token}`
                }
              });
              return next.handle(newRequest);
            }));
        }

        return throwError(err);
      }));
  }

  protected handleFailure(err) {
    this.auth.forceLogout();
    this.toast.warning('Your session has expired. Please login again');
    this.router.navigate(['/']);
  }
}
