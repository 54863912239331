import { Pagination } from './pagination';
import { Subject } from 'rxjs';

export class Paginator<T> {

  public perPage = 15;

  public currentPage = 1;

  public paginationState: Subject<{ perPage: number, page: number }> = new Subject<{ perPage: number, page: number }>();
  fund: any;

  public constructor(public pagination?: Pagination<T>) {
  }

  /**
   * Set new pagination value
   * @param pagination
   */
  public setPagination(pagination: Pagination<T>) {
    this.pagination = pagination;
  }

  /**
   * Has next page
   */
  public hasNexPage(): boolean {

    if (!this.pagination) {
      return false;
    }
    return this.pagination.current_page < this.pagination.last_page;
  }

  /**
   * Jump to next page
   */
  public nextPage(): number {
    if (!this.pagination) {
      return 0;
    }
    return this.hasNexPage() ? this.pagination.current_page + 1 : this.pagination.current_page;
  }

  /**
   * Has previous page
   */
  public hasPreviousPage(): boolean {
    if (!this.pagination) {
      return false;
    }
    return this.pagination.current_page !== 1;
  }

  /**
   * Jump to previous page
   */
  public previousPage(): number {
    if (!this.pagination) {
      return 0;
    }
    return this.hasPreviousPage() ? this.pagination.current_page - 1 : 1;
  }

  /**
   * Returns the data in the paginator instance
   */
  public data(): T[] {
    if (!this.pagination) {
      return [];
    }
    return this.pagination.data;
  }

  /**
   * Returns total record count
   */
  public total(): number {
    if (!this.pagination) {
      return 0;
    }

    return this.pagination.total;
  }

  /**
   * Updates current page and per page and afterwards changes the current paginated result
   * @param page
   * @param perPage
   */
  public updatePagination(page: number, perPage: number) {
    this.currentPage = page;
    this.perPage = perPage;

    this.paginationState.next({perPage, page});
  }
}
