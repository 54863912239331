import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectIfAuthenticatedGuard } from './guards/redirect-if-authenticated/redirect-if-authenticated.guard';
import { IsAuthenticatedGuard } from './guards/is-authenticated/is-authenticated.guard';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CanAccessRouteGuard } from './guards/can-access-route/can-access-route.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [RedirectIfAuthenticatedGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/guest/guest.module').then((m) => m.GuestModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./pages/project-mng/project-mng.module').then(
        (m) => m.ProjectMngModule,
      ),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: { roles: ['staff', 'project-manager', 'fund-manager', 'charitable-trust', 'advisor', 'amrp'] },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/user/user.module').then((m) => m.UserModule),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: { roles: ['staff'] },
  },
  {
    path: 'tickets',
    loadChildren: () =>
      import('./pages/ticket-mng/ticket-mng.module').then(
        (m) => m.TicketMngModule,
      ),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: {
      roles: [
        'staff',
        'project-manager',
        'fund-manager',
        'donor',
        'charitable-trust',
        'advisor',
        'amrp',
      ],
    },
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import('./pages/organization/organization.module').then(
        (m) => m.OrganizationModule,
      ),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./pages/category/category.module').then((m) => m.CategoryModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: 'training',
    loadChildren: () =>
      import('./pages/training/training.module').then((m) => m.TrainingModule),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: { roles: ['staff', 'project-manager', 'fund-manager'] },
  },
  {
    path: 'media',
    loadChildren: () =>
      import('./pages/media/media.module').then((m) => m.MediaModule),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: { roles: ['staff', 'project-manager', 'fund-manager', 'charitable-trust', 'advisor', 'amrp'] },
  },
  {
    path: 'donation',
    loadChildren: () =>
      import('./pages/donor/donor.module').then((m) => m.DonorModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: 'forms',
    loadChildren: () =>
      import('./pages/online-forms/online-forms.module').then(
        (m) => m.OnlineFormsModule,
      ),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: 'blackbaud',
    loadChildren: () =>
      import('./pages/blackbaud/blackbaud.module').then(
        (m) => m.BlackbaudModule,
      ),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: { roles: ['staff', 'project-manager', 'donor', 'fund-manager', 'charitable-trust', 'advisor', 'amrp'] },
  },
  {
    path: 'fund-raise',
    loadChildren: () =>
      import('./pages/fund-raise/fund-raise.module').then(
        (m) => m.FundRaiseModule,
      ),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: 'subscriber',
    loadChildren: () =>
      import('./pages/subscriber/subscriber.module').then(
        (m) => m.SubscriberModule,
      ),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: { roles: ['staff', 'project-manager'] },
  },
  {
    path: 'document',
    loadChildren: () =>
      import('./pages/document/document.module').then((m) => m.DocumentModule),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: {
      roles: ['staff', 'project-manager', 'fund-manager', 'charitable-trust', 'advisor', 'amrp', 'board-member'],
    },
  },
  // removing the calendar module as per the client request, but may be required in future
  // {
  //   path: 'calendar',
  //   loadChildren: () =>
  //     import('./pages/calendar/calender.module').then((m) => m.CalenderModule),
  //   canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
  //   data: {
  //   },
  // },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: 'cron-info',
    loadChildren: () =>
      import('./pages/cron-job-info/cron-job-info.module').then(
        (m) => m.CronJobInfoModule,
      ),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: { roles: ['staff'] },
  },
  {
    path: 'email-logs',
    loadChildren: () =>
      import('./pages/email-logs/email-logs.module').then(
        (m) => m.EmailLogsModule,
      ),
    canActivate: [IsAuthenticatedGuard, CanAccessRouteGuard],
    data: { roles: ['staff'] },
  },
  {
    path: 'foundation-information',
    loadChildren: () => import('./pages/foundation-information/foundation-information.module').then(m => m.FoundationInformationModule),
    data: { roles: ['staff']}
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
