import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    public snackBar: MatSnackBar
  ) {
  }

  /**
   * Default message toast
   * @param message
   * @param config
   */
  public message(message: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-default'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, null, config);
  }

  /**
   * Error message toast
   * @param message
   * @param config
   */
  public error(message: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-error'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, null, config);
  }

  /**
   * Success message toast
   * @param message
   * @param config
   */
  public success(message: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-success'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, null, config);
  }

  /**
   * Warning message toast
   * @param message
   * @param config
   */
  public warning(message: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-warning'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, null, config);
  }

  /**
   * Info message toast
   * @param message
   * @param config
   */
  public info(message: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-info'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, null, config);
  }

  /**
   * Provides default configuration for the toast
   * @param config
   */
  private defaultConfig(config?: MatSnackBarConfig): MatSnackBarConfig {
    config = config || {};
    config.duration = config.duration || 3000;
    config.verticalPosition = config.verticalPosition || 'top';

    return config;
  }

  /**
   * Default message toast
   * @param message
   * @param config
   * @param action
   */
  public messageWithAction(message: string, action: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-default'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, action, config);
  }

  /**
   * Error message toast
   * @param message
   * @param config
   * @param action
   */
  public errorWithAction(message: string, action: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-error'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, action, config);
  }

  /**
   * Success message toast
   * @param message
   * @param config
   * @param action
   */
  public successWithAction(message: string, action: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-success'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, action, config);
  }

  /**
   * Warning message toast
   * @param message
   * @param config
   * @param action
   */
  public warningWithAction(message: string, action: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-warning'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, action, config);
  }

  /**
   * Info message toast
   * @param message
   * @param config
   * @param action
   */
  public infoWithAction(message: string, action: string, config: MatSnackBarConfig = {}) {
    config = this.defaultConfig(config);
    config.panelClass = ['toast-info'].concat(typeof config.panelClass === 'string' ? [config.panelClass] : config.panelClass);
    return this.snackBar.open(message, action, config);
  }
}
