import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {StorageKeys} from "../../services/storage/storage-keys.enum";
import {AuthService} from "../../services/auth/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import {StorageService} from "../../services/storage/storage.service";

@Injectable({
  providedIn: 'root'
})
export class CanAccessRouteGuard  {
  public constructor(
    public auth: AuthService,
    public router: Router,
    public loader: NgxSpinnerService,
    public storage: StorageService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.isAuthenticated) {
      const scope = this.storage.getItem(StorageKeys.Scope);
      const domain = this.storage.getItem(StorageKeys.Domain);
      if (next.data.domain === domain || (next.data.roles && next.data.roles.indexOf(scope) === -1)) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      // authorised so return true
      return true;
    }
    // return this.router.createUrlTree(['/']);

  }

}
