import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageKeys } from '../../services/storage/storage-keys.enum';
import { StorageService } from '../../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})

export class ScopeHeaderService implements HttpInterceptor {

  constructor(
    public storage: StorageService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('Authorization')) {
      req = req.clone({
        setHeaders: {
          Scope: `${this.storage.getItem(StorageKeys.Scope)}`,
          Domain: `${this.storage.getItem(StorageKeys.Domain)}`
        }
      });
    } else {
      req = req.clone({
        setHeaders: {
          Domain: `${this.storage.getItem(StorageKeys.Domain)}`
        }
      });
    }
    return next.handle(req);
  }
}
