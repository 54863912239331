import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogSettings } from '../../global/common-media-handling/dialog-settings';
import { DialogComponent } from '../../global/common-media-handling/components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MediaGalleryService {

  constructor(
    public dialogRef: MatDialog
  ) {
  }

  /**
   * Opens alert dialog
   * @param data
   * @param config?
   */
  public show(data: {
                singleSelect: boolean, allowSelection: boolean, activeIndex: number,
                displayGallery: boolean, references: Array<string>, projectID:number, title?: string, imageType:string
              },
              config?: MatDialogConfig) {
    config = config || {};
    config.disableClose = true;
    config.data = data;
    config.panelClass = 'gallery-dialog';
    config.closeOnNavigation = true;
    return this.dialogRef.open(DialogComponent, config);
  }
}
