import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSelectComponent } from './components/file-select/file-select.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DialogComponent } from './components/dialog/dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MediaGalleryComponent } from './components/media-gallery/media-gallery.component';
import { MediaOpenButtonComponent } from './components/media-open-button/media-open-button.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [
        FileSelectComponent,
        DialogComponent,
        MediaGalleryComponent,
        MediaOpenButtonComponent
    ],
    imports: [
        CommonModule,
        NgxDropzoneModule,
        MatTabsModule,
        FormsModule,
        FontAwesomeModule,
        MatProgressBarModule,
        NgbPaginationModule,
    ],
    exports: [FileSelectComponent, MediaGalleryComponent, MediaOpenButtonComponent]
})
export class CommonMediaHandlingModule {
}
