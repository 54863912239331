import { Injectable } from '@angular/core';
import { StorageKeys } from './storage-keys.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  /**
   * Driver being used
   */
  public driver(): Storage {
    return window.localStorage;
  }

  /**
   * Get item from storage
   * @param key
   */
  public getItem(key: StorageKeys): any {
    if(this.driver().getItem(key) && this.driver().getItem(key) !== 'undefined'){
      return JSON.parse(this.driver().getItem(key));
    }
    else{
      return this.driver().getItem(key);
    }
  }

  /**
   * Save item in storage
   * @param key
   * @param value
   */
  public saveItem(key: StorageKeys, value: any): void {
    return this.driver().setItem(key, JSON.stringify(value));
  }

  /**
   * Total length
   */
  public length(): number {
    return this.driver().length;
  }

  /**
   * Remove item from storage
   * @param key
   */
  public removeItem(key: StorageKeys): void {
    return this.driver().removeItem(key);
  }

  /**
   * Clear complete storage
   */
  public clear(): void {
    this.driver().clear();
  }
}
