import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthHeaderService } from './interceptors/auth-header/auth-header.service';
import { RefreshTokenService } from './interceptors/refresh-token/refresh-token.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScopeHeaderService } from './interceptors/scope-header/scope-header.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShowErrorComponent } from './global/show-error/show-error.component';
import { CommonMediaHandlingModule } from './global/common-media-handling/common-media-handling.module';
import { AlertModule } from './global/alert/alert.module';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RemainderModule } from './global/remainder/remainder.module';
import { QuillModule } from 'ngx-quill';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, ShowErrorComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgbModule,
    FontAwesomeModule,
    // NgxStripeModule.forRoot(environment.stripe.key),
    CommonMediaHandlingModule,
    AlertModule,
    RemainderModule,
    RecaptchaV3Module,
    NgMultiSelectDropDownModule.forRoot(),
    SlickCarouselModule,
    NgxMaskPipe,
    QuillModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScopeHeaderService,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaKeys.SiteKey,
    },
    provideNgxMask(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ShowErrorComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
