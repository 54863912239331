import {Injectable} from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Token} from './token';
import {environment} from '../../../environments/environment';
import {filter, map, retry, tap} from 'rxjs/operators';
import {StorageService} from '../storage/storage.service';
import {StorageKeys} from '../storage/storage-keys.enum';
import {User, UserProfile} from '../user/user';
import {Role} from "../role/role";
import {Router} from "@angular/router";

export interface EmailToken {
  id: number;
  email: string;
  token: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  /**
   * Current authentication state
   */
  public isAuthenticated = false;

  public userState: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  /**
   * Authentication State
   */
  public authState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAuthenticated);
  public roles: Role[];

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public router: Router,
  ) {
    super();

    this.authState
      .pipe(filter(state => state === false))
      .subscribe(() => this.userState.next(null));
  }

  /**
   *
   * Login a user in system
   *
   * @param username
   * @param password
   * @param captchaToken
   */
  public login(username: string, password: string, captchaToken: string): Observable<Token> {
    const data = {
      username,
      password,
      recaptcha_token: captchaToken,
      grant_type: 'password',
      client_id: environment.appSecrets.RaiserEdgeClientId,
      client_secret: environment.appSecrets.clientSecret
    };

    return this.http.post<Token>(this.url('oauth/token'), data, {headers: this.headers(false)})
      .pipe(tap(token => this.saveToken(token)))
      .pipe(tap(() => this.updateAuthState(true)));
  }

  /**
   * Save tokens in storage
   * @param token
   */
  protected saveToken(token: Token) {
    this.storage.saveItem(StorageKeys.AccessToken, token.access_token);
    this.storage.saveItem(StorageKeys.RefreshToken, token.refresh_token);
  }

  /**
   * Refresh the access token
   */
  public refreshToken(): Observable<Token> {
    const data = {
      grant_type: 'refresh_token',
      refresh_token: this.storage.getItem(StorageKeys.RefreshToken),
      client_id: environment.appSecrets.RaiserEdgeClientId,
      client_secret: environment.appSecrets.clientSecret,
    };

    return this.http.post<Token>(this.url('oauth/token'), data, {headers: this.headers(false)})
      .pipe(retry(1))
      .pipe(tap(token => this.saveToken(token)));
  }

  /**
   * Update current auth status
   * @param state
   */
  public updateAuthState(state: boolean) {
    this.isAuthenticated = state;
    this.authState.next(this.isAuthenticated);
  }

  /**
   * Initializes the authentication state in app
   */
  public initAuthState() {
    if (!!this.storage.getItem(StorageKeys.AccessToken) && !!this.storage.getItem(StorageKeys.RefreshToken)) {
      this.updateAuthState(true);
    } else {
      this.forceLogout();
    }
  }

  /**
   * Clears storage and updates auth state to false
   */
  public forceLogout() {
    this.storage.removeItem(StorageKeys.RefreshToken);
    this.storage.removeItem(StorageKeys.AccessToken);
    this.storage.removeItem(StorageKeys.Scope);
    this.storage.removeItem(StorageKeys.PreviousUser);
    this.storage.removeItem(StorageKeys.ImpersonateToken);
    this.updateAuthState(false);
  }

  /**
   * Get login user details
   */
  public authUser(): Observable<User> {

    return this.http.get<User>(this.apiUrl('account'), {headers: this.headers(true)})
      .pipe(tap(user => this.userState.next(user)))
      .pipe(tap(() => this.saveScope()));
  }

  public getUser(): Observable<User | null> {
    return this.userState.asObservable();
  }

  public forgotPassword(email: string, recaptchaToken: string) {
    const options = {
      email,
      recaptchaToken,
    };
    return this.http.post(this.apiUrl('password/email'), options, {headers: this.headers(false)}
    );
  }
  /**
   * Save tokens in storage
   * @param token
   */
  public saveScope() {
    this.roles = this.userState.value.roles;
    if (this.roles.some(x => x.name === 'staff') && !this.storage.getItem(StorageKeys.Scope)) {
      this.storage.saveItem(StorageKeys.Scope, 'staff');
    }
    if (this.roles.some(x => x.name === 'project-manager') && !this.storage.getItem(StorageKeys.Scope)) {
      this.storage.saveItem(StorageKeys.Scope, 'project-manager');
    }
    if (this.roles.some(x => x.name === 'fund-manager') && !this.storage.getItem(StorageKeys.Scope)) {
      this.storage.saveItem(StorageKeys.Scope, 'fund-manager');
    }
    if (this.roles.some(x => x.name === 'board-member') && !this.storage.getItem(StorageKeys.Scope)) {
      this.router.navigate(['calender']);
      this.storage.saveItem(StorageKeys.Scope, 'board-member');
    }
    if (this.roles.some(x => x.name === 'donor') && !this.storage.getItem(StorageKeys.Scope)) {
      this.storage.saveItem(StorageKeys.Scope, 'donor');
    }
  }

  /**
   *
   * @param token
   */
  public verify(token): Observable<EmailToken> {
    return this.http.get<EmailToken>(this.apiUrl('auth/email-verify/' + token), {headers: this.headers(false)});
  }

  /**
   *
   * @param email
   */
  public emailverify(email:string): Observable<any> {
    const options = {
      email
    };
    return this.http.post<any>(this.apiUrl('email-verify/email'), options, {headers: this.headers(false)});
  }
}
