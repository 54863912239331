import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageKeys } from 'src/app/services/storage/storage-keys.enum';
import { StorageService } from 'src/app/services/storage/storage.service';
import { AlertComponent } from '../../alert/components/alert/alert.component';
import { RemainderInput } from '../remainder-input';

@Component({
  selector: 'app-remainder',
  templateUrl: './remainder.component.html',
  styleUrls: ['./remainder.component.scss']
})
export class RemainderComponent implements OnInit {

  public scope: string;
  constructor(
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemainderInput,
    public storage: StorageService
  ) {
  }

  ngOnInit() {
    this.scope = this.storage.getItem(StorageKeys.Scope);
  }

  /**
   * Dismiss with yes
   */
  public closeRemainder() {
    this.dialogRef.close();
  }

}
