<div class="dialog-container">
  <div class="header">
    <h3 class="mb-2 d-flex justify-content-between">
      {{ !!data?.title ? data?.title : 'Add Media' }}

      <div class="header-actions">
        <button class="btn-link" type="button" (click)="dismissDialog()">X</button>
      </div>
    </h3>

  </div>
  <div class="tabs">
    <mat-tab-group [selectedIndex]="data.activeIndex" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="Upload">
        <div *ngIf="hasError == true" class="text-danger text-center big mt-1">{{errorMessage}}
        </div>
        <mat-progress-bar *ngIf="progress" class="example-margin" color="warm" mode="determinate" [value]="progress">
        </mat-progress-bar>

        <app-file-select (filesSelected)="uploadFiles($event)" [imageType]="this.imageType">
        </app-file-select>


      </mat-tab>
      <mat-tab label="Gallery" *ngIf="data.displayGallery">
        <app-media-gallery *ngIf="showGallery"
                           [singleSelect]="data.singleSelect"
                           [allowSelection]="data.allowSelection"
                           (mediaChange)="dismissWithMedia($event)"
                           [references]="data.references"
                           [projectID]= "data.projectID"
        ></app-media-gallery>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
