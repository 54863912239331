<div class="{{scope}}">
    <h4 mat-dialog-title class="word-break text-center mb-4">Reminder</h4>
    <p class="alert-message text-center">
        {{ data.message }}
    </p>
    <div class="alert-actions text-center pt-4">
        <button class="btn-common btn-secondary me-4" (click)="closeRemainder()">Okay</button>
    </div>
</div>

