import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertInput, AlertOutput } from '../../alert-input';
import { StorageKeys } from '../../../../services/storage/storage-keys.enum';
import { StorageService } from '../../../../services/storage/storage.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  public scope: string;
  constructor(
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertInput,
    public storage: StorageService
  ) {
  }

  ngOnInit() {
    this.scope = this.storage.getItem(StorageKeys.Scope);
  }

  /**
   * Dismiss with yes
   */
  public closeWithAcceptance() {
    this.dialogRef.close({
      data: true
    } as AlertOutput);
  }

  /**
   * Close modal/dialog
   */
  public dismiss() {
    this.dialogRef.close({
      data: false
    } as AlertOutput);
  }

}
