    <div class="container d-flex align-item-center h-100 d-flex align-items-center">
      <div class="col-lg-8 offset-lg-2 mb-5 mb-3">
        <svg class="mb-5 mw-100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          width="418px" height="178px" viewBox="0 0 418 178" version="1.1">



          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="404-Sample2" transform="translate(-455.000000, -230.000000)" fill="#006CC3" fill-rule="nonzero">
              <g id="Group" transform="translate(455.000000, 230.868159)">
                <path
                  d="M205.544569,-1.27897692e-13 C167.605295,-1.27897692e-13 140.365298,24.3408074 140.365298,81.1360143 C140.365298,145.503915 167.605286,162.542482 205.544569,162.542482 C243.483852,162.542482 272.346578,143.881199 272.346578,81.1360143 C272.346556,14.0635783 243.483843,-1.27897692e-13 205.544569,-1.27897692e-13 Z M205.793961,141.176668 C179.516338,141.176668 165.787924,125.760261 165.787924,81.1775805 C165.787924,41.8399169 180.078306,21.0953652 206.355951,21.0953652 C232.633596,21.0953652 246.923978,34.7216649 246.923978,81.1775805 C246.923939,124.636325 232.071584,141.176668 205.793961,141.176668 Z"
                  id="Shape" />
                <path
                  d="M117.64723,117.647221 L101.960913,117.647221 L101.960913,71.4550623 C101.960908,66.3460041 97.8191992,62.2042953 92.710141,62.2042905 L89.0345318,62.2042905 C86.5810715,62.2042893 84.2280981,63.1789218 82.4932392,64.9137798 C80.7583804,66.6486378 79.7837468,69.0016107 79.7837468,71.4550711 L79.7837468,117.647221 L35.5380358,117.647221 C33.6866419,117.647223 31.9704415,116.677729 31.0148247,115.092025 C30.0592079,113.506322 30.0037392,111.535995 30.8686315,109.899041 L77.6977991,21.2662781 C78.8775863,19.0333021 79.0931513,16.4150584 78.2944657,14.019191 C77.4957802,11.6233235 75.7522888,9.65815019 73.4686223,8.57974991 L70.7041792,7.27431928 C66.2459573,5.16904778 60.9200509,6.93016589 58.5957495,11.2782152 L1.48140685,118.121413 C0.508828349,119.940803 -1.91988833e-07,121.971987 -8.24229573e-13,124.035015 L-8.24229573e-13,124.035015 C-1.03124716e-12,127.361872 1.32158891,130.552469 3.67403212,132.904911 C6.02647533,135.257354 9.21707286,136.578942 12.5439294,136.57894 L79.7837468,136.57894 L79.7837468,165.787915 C79.7837468,168.728786 80.9520032,171.549207 83.031513,173.628717 C85.1110228,175.708227 87.9314434,176.876483 90.8723143,176.876483 L90.8723364,176.876483 C93.8132074,176.876483 96.6336279,175.708227 98.7131377,173.628717 C100.792648,171.549207 101.960904,168.728786 101.960904,165.787915 L101.960904,136.578958 L117.647208,136.578958 C122.875058,136.578958 127.113067,132.340948 127.113067,127.113098 L127.113067,127.113098 C127.113077,121.88525 122.875078,117.647233 117.64723,117.647221 Z"
                  id="Path" />
                <path
                  d="M408.114162,117.647221 L392.427858,117.647221 L392.427858,71.4550623 C392.427853,66.3459989 388.286137,62.204288 383.177073,62.2042905 L379.501464,62.2042905 C377.048004,62.2042893 374.69503,63.1789218 372.960171,64.9137798 C371.225313,66.6486378 370.250679,69.0016107 370.250679,71.4550711 L370.250679,117.647221 L326.004968,117.647221 C324.153573,117.647226 322.437371,116.677732 321.481753,115.092028 C320.526136,113.506323 320.470669,111.535995 321.335564,109.899041 L368.164727,21.2662958 C369.344519,19.0333184 369.560087,16.4150712 368.761402,14.0191999 C367.962717,11.6233285 366.219224,9.65815173 363.935555,8.57974991 L361.171111,7.27431928 C356.712888,5.16904736 351.386981,6.93016529 349.062677,11.2782152 L291.948339,118.121413 C290.975761,119.940803 290.466932,121.971987 290.466932,124.035015 L290.466932,124.035015 C290.466932,130.962834 296.083039,136.57894 303.010857,136.57894 L370.250679,136.57894 L370.250679,165.787915 C370.250679,171.911962 375.2152,176.876483 381.339247,176.876483 L381.339247,176.876483 C387.463293,176.876483 392.427814,171.911962 392.427814,165.787915 L392.427814,136.578958 L408.114118,136.578958 C413.341968,136.578958 417.579978,132.340948 417.579978,127.113098 L417.579978,127.113098 C417.579987,121.885259 413.342002,117.647245 408.114162,117.647221 L408.114162,117.647221 Z"
                  id="Path" />
              </g>
            </g>
          </g>
        </svg>
        <h2 class="text-primary text-uppercase line-break position-relative">Seems like you are lost.</h2>
        <p class="mt-5 mb-4 pb-2">The page you are looking for does not exist or has been moved. In any case, we are sorry for that. Don't be panic we can help you go back to the home page.</p>
        <a href="/" class="btn-common btn-primary text-decoration-none">Go Back Home</a>
      </div>
    </div>



