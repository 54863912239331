import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media } from '../../../../services/media/media';
import { MediaGalleryService } from '../../../../services/media-gallery/media-gallery.service';
import { DialogOutput } from '../../dialog-settings';
import {AlertService} from '../../../../services/alert/alert.service';

@Component({
  selector: 'app-media-open-button',
  templateUrl: './media-open-button.component.html',
  styleUrls: ['./media-open-button.component.scss']
})
export class MediaOpenButtonComponent implements OnInit {

  @Input()
  public imageType:string = null;

  @Input()
  public singleSelect = false;

  @Input()
  public allowSelection = false;

  @Input()
  public activeIndex = 0;

  @Input()
  public buttonLabel = 'Add Media';

  @Input()
  public displayGallery = true;

  @Input()
  public references: Array<string>;

  @Input()
  public projectID:number = 0;

  @Input()
  public isWorkForceFDR: boolean;

  @Input()
  public title: string;

  @Output()
  public mediaChange: EventEmitter<Media[]> = new EventEmitter<Media[]>();

  @Output()
  public dialogClose: EventEmitter<any> = new EventEmitter();

  constructor(
    public mediaGallery: MediaGalleryService,
    public alert: AlertService,
  ) {
  }

  ngOnInit() {
  }

  /**
   * Open Dialog to display media library
   */
  public openMedia() {
    const {singleSelect} = this;
    const {allowSelection} = this;
    const {activeIndex} = this;
    const {displayGallery} = this;
    const {references} = this;
    const {projectID} = this;
    const { title } = this;
    const {imageType} = this;

    if (this.isWorkForceFDR) {
      this.alert.showRemainder({
        message: `Please note, all signatures must be handwritten or signed with an electronic date stamp to be considered valid.`,
      }).afterClosed().subscribe(() => {
        this.mediaGallery.show({
          singleSelect,
          allowSelection,
          activeIndex,
          displayGallery,
          references,
          projectID,
          imageType
        })
          .afterClosed().subscribe((result: DialogOutput) => {
          if (result.data) {
            this.mediaChange.emit(result.data);
          }
          this.dialogClose.emit();
        });
      });
    } else {
      this.mediaGallery.show({
        singleSelect,
        allowSelection,
        activeIndex,
        displayGallery,
        references,
        projectID,
        title,
        imageType
      })
        .afterClosed().subscribe((result: DialogOutput) => {
        if (result.data) {
          this.mediaChange.emit(result.data);
        }
        this.dialogClose.emit();
      });
    }
  }
}
