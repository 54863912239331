// This file can be replaced during build by using the fileReplacements array.
// ng build --prod replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.

export const environment = {
  production: false,
  apiUrl: 'https://app.joytotheworldfoundation.org',
  apiVersion: 'api/v1',
  appSecrets: {
    RaiserEdgeClientId: '1',
    clientSecret: 'aH1timJlpnHiH90ezMqyJFUvDpyFto52zwSR02xC'
  },
  googleApiKey: 'test_cli',
  donorServicePortal: {
    url: 'https://api.newhorizonsfoundation.com/donations',
  },
  stripe: {
    key: 'pk_test_AHcQkKpdaGpOfHfII9uvMUri'
  },
  blackbaud: {
    RaiserEdgeClientId: '',
    FinancialEdgeClientId: '',
    RaiserEdgeRedirectUri: 'http://localhost:4200/blackbaud/callback?type=RE',
    FinancialEdgeRedirectUri: 'http://localhost:4200/blackbaud/callback?type=FE',
    baseUrl: 'https://oauth2.sky.blackbaud.com/'
  },
  recaptchaKeys: {
    SiteKey: '6LckZiUlAAAAAEXlA8SZ5NNFFjLKzmyYRT3RTsNp',
  },
  fullDomains: {
    newHorizonAdminStg: 'stg.newhorizonsfoundation.com',
  },
};

