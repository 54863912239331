import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AppStatusService {

  constructor(
    public updates: SwUpdate,
    public toast: ToastService,
  ) {
  }

  /**
   * Initialize the service
   */
  public init() {
    this.updates.available.subscribe(event => {
      this.toast.infoWithAction('Application has been updated. Reload now to use latest app.', 'Reload')
        .onAction().subscribe(() => this.reloadApp());
    });
  }

  protected reloadApp() {
    window.location.reload();
  }
}
