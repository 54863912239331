import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogOutput, DialogSettings } from '../../dialog-settings';
import { MediaService } from '../../../../services/media/media.service';
import { filter, map } from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Media } from '../../../../services/media/media';
import { MediaGalleryComponent } from '../media-gallery/media-gallery.component';
import { ToastService } from '../../../../services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public progress = 0;
  public hasError = false;
  public showGallery = false;
  public imageType = null;
  public errorMessage;

  @ViewChild('mediaGalleryComponent', {static: true})
  public mediaGalleryComponent: MediaGalleryComponent


  constructor(
    public loader: NgxSpinnerService,
    public dialogRef: MatDialogRef<DialogComponent>,
    public media: MediaService,
    public toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: DialogSettings
  ) {
  }

  ngOnInit() {
    if (this.data.activeIndex === 1) {
      this.showGallery = true;
    }
    if(this.data.imageType) {
      this.imageType = this.data.imageType
    }
  }

  /**
   * Upload new media
   * @param Files
   */
  public uploadFiles(Files: File[]) {
    const formData = new FormData();

    Files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    formData.append("image_type", this.imageType);
    this.media.storeWithProgress(formData)
      .pipe(map(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        }
        return event;
      }))
      .pipe(filter(event => event.type === HttpEventType.Response))
      .subscribe((result: HttpResponse<Media[]>) => this.updateGallery(result.body),
        (e) => {
          if (e.status === 413 || e.status === 403) {
            this.toast.error('Please try again and make sure to check the file type and size allowed.');
          }
          this.progress = 0;
          Object.entries(e.error.errors).forEach(error => {
            this.errorMessage = error[1];
          });
          this.hasError = true;
        });
  }

  /**
   *
   */
  public updateGallery(medias: Media[]) {
    this.progress = 0;
    if (!this.data.displayGallery) {
      this.dismissWithMedia(medias);
    }
    if (this.data.displayGallery) {
      this.data.activeIndex = 1;
      this.showGallery = true;
      this.toast.success('Media uploaded successfully');
    }
  }

  /**
   *
   * @param event
   */
  public onTabChange(event) {
    if (event.index === 0) {
      this.showGallery = false;
    }
    if (event.index === 1) {
      this.showGallery = true;
    }
  }

  /**
   * Dismiss dialog box & output media
   * @param medias
   */
  public dismissWithMedia(medias: Media[]) {
    const data: DialogOutput = {
      data: medias
    };
    this.dialogRef.close(data);
  }

  /**
   * Close media dialog
   */
  public dismissDialog() {
    this.dialogRef.close({data: undefined} as DialogOutput);
  }
}
