import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

export class BaseService {

  constructor() {
  }

  /**
   * Base Url
   * @param url
   */
  public apiUrl(url: string): string {
    return `${environment.apiUrl}/${environment.apiVersion}/${url}`;
  }

  /**
   * Creates common headers
   * @param authenticated
   */
  public headers(authenticated = true): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    if (authenticated) {
      headers = headers.append('Authorization', 'Bearer');
    }

    return headers;
  }

  /**
   * Creates common headers
   * @param authenticated
   */
  public donorServiceHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    headers = headers.append('Authorization', 'Bearer');
    return headers;
  }

  public url(url: string): string {
    return `${environment.apiUrl}/${url}`;
  }
}
