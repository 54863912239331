import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Media } from './media';
import { Paginator } from '../../helpers/pagination/paginator';
import { Category } from '../category/category';
import { Pagination } from '../../helpers/pagination/pagination';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaService extends BaseService {

  constructor(
    public  http: HttpClient) {
    super();
  }

  /**
   * Store new media item.
   * @param media
   */
  public store(media: FormData): Observable<Media> {
    return this.http.post<Media>(this.apiUrl('media'), media, {headers: this.headers()});
  }

  /**
   * Store new media item.
   * @param media
   */
  public storeWithProgress(media: FormData): Observable<HttpEvent<Media[]>> {
    const request = new HttpRequest('post', this.apiUrl('media'), media, {
      headers: this.headers(),
      reportProgress: true,
    });
    return this.http.request<Media[]>(request);
  }

  /**
   * List paginated media items
   * @param page
   * @param perPage
   * @param params
   */
  public listPaginated(page: number = 1, perPage: number = 15, params = {
    references: null,
    scope: null,
    projectID: null
  }): Observable<Paginator<Media>> {
    const options = {
      headers: this.headers(),
      params: {
        perPage: perPage.toString(),
        page: page.toString(),
        references: params.references ? params.references.toString() : null,
        scope: params.scope ? params.scope.toString() : null,
        projectID: params.projectID ? params.projectID : '',
      }
    };
    return this.http.get<Pagination<Media>>(this.apiUrl('media'), options)
      .pipe(switchMap(paginatedMedia => of(new Paginator(paginatedMedia))));
  }

  /**
   * Fetch specific media item
   * @param id
   */
  public show(id) {
    this.http.get(this.apiUrl(`media/${id}`), {headers: this.headers()});
  }

  /**
   * Delete a media item
   * @param id
   */
  public delete(id) {
    return this.http.delete(this.apiUrl(`media/${id}`), {headers: this.headers()});
  }

  /**
   *
   * @param media
   */
  public deleteMultiple(media: Media[]) {
    const ids = [];
    for (const item of media) {
      if (item.media_id) {
        ids.push(item.media_id);
      } else {
        ids.push(item.id);
      }
    }
    return this.http.post(this.apiUrl(`media/multiple`), {mediaIds: ids}, {headers: this.headers()});
  }

  public deleteAttachmentMedia(id) {
    return this.http.delete(this.apiUrl(`attachment/${id}`), {headers: this.headers()});
  }

  public deleteMultiplePendingAttachment(media: Media[]) {
    const ids = [];
    for (const item of media) {
      if (item.media_id) {
        ids.push(item.media_id);
      } else {
        ids.push(item.id);
      }
    }
    return this.http.post(this.apiUrl(`pending-form-attachment/multiple`), {mediaIds: ids}, {headers: this.headers()});
  }

  public deletePendingAttachment(id) {
    return this.http.delete(this.apiUrl(`pending-form-attachment/${id}`), {headers: this.headers()});
  }

  /*
    Function to delete Bank Info Attachment When Yes Checkbox is checked.
   */
  public deleteMultipleBankInfoAttachment(bankInfoAttachments: Media[]) {
    const ids = [];
    for (const bankInfoAttachment of bankInfoAttachments) {
      if (bankInfoAttachment.media_id) {
        ids.push(bankInfoAttachment.media_id);
      } else {
        ids.push(bankInfoAttachment.id);
      }
    }

    return this.http.post(this.apiUrl(`bank-info-attachment/delete-multiple`), {mediaIds: ids}, {headers: this.headers()});
  }
}
