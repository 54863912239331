import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../../services/storage/storage-keys.enum';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../environments/environment';
import {ToastService} from "../../services/toast/toast.service";

@Injectable({
  providedIn: 'root'
})
export class AuthHeaderService implements HttpInterceptor {

  constructor(
    public storage: StorageService,
    private router: Router,
    private auth: AuthService,
    public toast: ToastService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('Authorization')) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.storage.getItem(StorageKeys.AccessToken)}`
        }
      });
    }
    return next.handle(req).pipe(catchError((err: any) => {
      if (err.status === 401 && !this.router.routerState.snapshot.url.includes('/donation')) {
          this.auth.forceLogout();
          this.router.navigate(['/']);
          this.toast.error('Session timeout');
      }
      if (err.status === 404 && !this.router.routerState.snapshot.url.includes('/donation')) {
        this.auth.forceLogout();
        this.router.navigate(['/']);
        this.toast.error('Session timeout');
      }
      if (err.status === 419) {
        this.toast.error('Please wait for sometime while data is uploading');
      }

      return throwError(err);
    }));
  }
}
