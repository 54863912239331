import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media } from '../../../../services/media/media';
import { MediaService } from '../../../../services/media/media.service';
import { AlertService } from '../../../../services/alert/alert.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { AlertOutput } from '../../../alert/alert-input';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Paginator } from '../../../../helpers/pagination/paginator';
import { Project } from '../../../../services/project-mng/project';
import { StorageService } from '../../../../services/storage/storage.service';
import { StorageKeys } from '../../../../services/storage/storage-keys.enum';

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit {

  @Input()
  public singleSelect = false;

  @Input()
  public allowSelection = false;

  @Input()
  public activeTab = 'upload';

  @Input()
  public references: Array<string>;

  @Input()
  public projectID:number = 0;

  @Output()
  public mediaChange: EventEmitter<Media[]> = new EventEmitter<Media[]>();

  public medias: Media[] = [];
  public selectedMedia: Media[] = [];
  public paginatedMedias: Paginator<Media> = new Paginator<Media>();

  public itemsPerPage = 20;
  public page = 1;
  public previousPage: any;
  public scope = null;

  constructor(
    public media: MediaService,
    public alert: AlertService,
    public toast: ToastService,
    public storage: StorageService,
  ) {
  }

  faTrashAlt = faTrashAlt;
  faCheck = faCheck;

  ngOnInit() {
    this.fetchMedia(1, this.itemsPerPage, this.projectID);
    this.scope = this.storage.getItem(StorageKeys.Scope);
    this.paginatedMedias.paginationState.subscribe(pagination => this.fetchMedia(pagination.page, pagination.perPage, this.projectID));
  }

  /**
   * Fetch media items
   */
  public fetchMedia(page: number = 1, perPage: number = this.itemsPerPage, projectId:number = 0) {
    this.selectedMedia = [];
    const options = {
      references: this.references,
      scope: this.scope,
      projectID : projectId
    };
    this.media.listPaginated(page, perPage, options)
      .subscribe(paginatedData => this.paginatedMedias.setPagination(paginatedData.pagination));
  }

  /**
   * Pagination
   * @param page
   */
  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.fetchMedia(page, this.itemsPerPage);
    }
  }

  /**
   * Fetch projects & reset current page to 1
   */
  public changeItemsPerPage() {
    this.page = 1;
    this.fetchMedia();
  }

  /**
   * Collect selected media items
   * @param media
   */
  public onSelectionChange(media: Media) {
    if (this.allowSelection) {
      if (this.singleSelect) {
        this.selectedMedia.splice(0, this.selectedMedia.length);
        this.selectedMedia.push(media);
      } else if (!this.singleSelect) {
        if (this.selectedMedia.includes(media)) {
          const index = this.selectedMedia.indexOf(media);
          this.selectedMedia.splice(index, 1);
        } else {
          this.selectedMedia.push(media);
        }
      }
    }
  }

  /**
   * Emit event with selected media
   */
  public onMediaSelected() {
    this.mediaChange.emit(this.selectedMedia);
  }

  /**
   *
   * @param item
   */
  public isSelected(item: Media) {
    return this.selectedMedia.includes(item);
  }

  /**
   * Delete media item
   * @param media
   */
  public deleteMedia(media: Media) {
    this.alert.showAlert({
      title: `Delete media ${media.display_name}`,
      message: 'Are you sure you want to delete the media?'
    }).afterClosed().subscribe((result: AlertOutput) => {
      if (result && result.data) {
        this.media.delete(media.id)
          .subscribe(() => {
              this.toast.success('Media deleted successfully.');
              this.fetchMedia();
            },
            (error) => {
              if (error.status === 422) {
                this.toast.error('Sorry. Unable to delete media. The media is in use.');
              }
              if (error.status === 404) {
                this.toast.error('Sorry. Unable to delete media.');
              }
            }
          );
      }
    });
  }

  /**
   *
   * @param media
   */
  public isDocument(media: Media) {
    return (media.mime_type === 'doc' || media.mime_type === 'docx');
  }

  /**
   *
   * @param media
   */
  public isXls(media: Media) {
    return (media.mime_type === 'xls' || media.mime_type === 'xlsx');
  }

  /**
   *
   * @param media
   */
  public isPDF(media: Media) {
    return (media.mime_type === 'pdf');
  }

  /**
   *
   * @param media
   */
  public isImage(media: Media) {
    const mimeTypes = ['jpg', 'jpeg', 'bmp', 'png', 'gif', 'svg'];
    return mimeTypes.includes(media.mime_type);
  }

  /**
   *
   * @param media
   */
  public isVideo(media: Media) {
    const mimeTypes = ['mp4', 'qt', 'webm', 'mov', 'wmv', 'asf', 'mkv', 'bin', 'avi'];
    return mimeTypes.includes(media.mime_type);
  }
}
