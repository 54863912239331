import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss']
})
export class FileSelectComponent {

  @Output()
  public filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Input() imageType!: string;

}
