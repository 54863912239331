import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StorageKeys } from '../../services/storage/storage-keys.enum';
import { StorageService } from '../../services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectIfAuthenticatedGuard {
  public constructor(
    public storage: StorageService,
    public router: Router,
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !!this.storage.getItem(StorageKeys.AccessToken) &&
      !!this.storage.getItem(StorageKeys.RefreshToken)
    ) {
      if (this.storage.getItem(StorageKeys.Scope) === 'board-member') {
        return this.router.createUrlTree(['/calendar']);
      } else {
        return this.router.createUrlTree(['/dashboard']);
      }
    }

    return true;
  }
}
