import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { finalize, switchMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import {StorageKeys} from "../../services/storage/storage-keys.enum";
import {StorageService} from "../../services/storage/storage.service";
import {Role} from "../../services/role/role";

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard  {
  public projectId;
  public roles: Role[];

  public constructor(
    public auth: AuthService,
    public router: Router,
    public loader: NgxSpinnerService,
    public currentRoute: ActivatedRoute,
    public storage: StorageService,
  ) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.projectId = next.queryParams.project_id;
    if (this.auth.isAuthenticated) {
      if (!this.auth.userState.value) {
        this.loader.show();
        return this.auth.authUser()
          .pipe(switchMap(() => of(true)))
          .pipe(finalize(() => this.loader.hide()));
      }
      return true;
    }
    else{
      return this.router.createUrlTree(['/'],{
        queryParams: {
          redirectURL: state.url,
        }
      });
    }
    if (this.projectId) {
      return this.router.createUrlTree(['/'], {
        queryParams: {
          project_id: this.projectId,
        }
      });
    } else {
      return this.router.createUrlTree(['/']);
    }

  }
  /**
   * Save tokens in storage
   * @param token
   */
  public saveScope() {
    this.roles = this.auth.userState.value.roles;
    if (this.roles.some(x => x.name === 'staff') && !this.storage.getItem(StorageKeys.Scope)) {
      this.storage.saveItem(StorageKeys.Scope, 'staff');
    }
    if (this.roles.some(x => x.name === 'project-manager') && !this.storage.getItem(StorageKeys.Scope)) {
      this.storage.saveItem(StorageKeys.Scope, 'project-manager');
    }
    if (this.roles.some(x => x.name === 'fund-manager') && !this.storage.getItem(StorageKeys.Scope)) {
      this.storage.saveItem(StorageKeys.Scope, 'fund-manager');
    }
    if (this.roles.some(x => x.name === 'board-member') && !this.storage.getItem(StorageKeys.Scope)) {
      this.router.navigate(['contact']);
      this.storage.saveItem(StorageKeys.Scope, 'board-member');
    }
    if (this.roles.some(x => x.name === 'donor') && !this.storage.getItem(StorageKeys.Scope)) {
      this.storage.saveItem(StorageKeys.Scope, 'donor');
    }
  }
}
