import { Injectable } from '@angular/core';
import { AlertInput } from '../../global/alert/alert-input';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertComponent } from '../../global/alert/components/alert/alert.component';
import { RemainderComponent } from 'src/app/global/remainder/component/remainder.component';
import { RemainderInput } from 'src/app/global/remainder/remainder-input';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    public dialogRef: MatDialog
  ) {
  }

  /**
   * Opens alert dialog
   * @param data
   * @param config
   */
  public showAlert(data: AlertInput, config?: MatDialogConfig) {
    config = config || {};
    config.data = data;
    return this.dialogRef.open(AlertComponent, config);
  }
  public showRemainder(data: RemainderInput, config?: MatDialogConfig) {
    config = config || {};
    config.data = data;
    return this.dialogRef.open(RemainderComponent, {
      data: data,
      height: '250px',
      width: '600px',
    });
  }
}
