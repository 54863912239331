<div class="file-select-container">
  <ngx-dropzone (change)="filesSelected.emit($event.addedFiles)" class="px-4">
    <ngx-dropzone-label *ngIf="imageType === 'featuredImage'; else elseBlock">Drop Files Here <br>
      <p>
        Allowed file types:  jpeg, bmp, png, gif, svg, jpg, tiff. <br>
        Max allowed size: 40560 KB
      </p>
    </ngx-dropzone-label>
    <ng-template #elseBlock>
      <ngx-dropzone-label *ngIf="imageType === 'storyImage'; else imageGalleryBlock">Drop Files Here <br>
        Allowed file types: jpeg, bmp, png, gif, svg, jpg, tiff.<br>
        Max allowed size: 40560 KB
      </ngx-dropzone-label>
    </ng-template>

    <ng-template #imageGalleryBlock>
      <ngx-dropzone-label *ngIf="imageType === 'imageGallery'; else globalBlock">Drop Files Here <br>
        Allowed file types: jpeg, bmp, png, gif, svg, jpg, tiff, mp4, mov, wmv, webm, avi, flv, mkv, mts. <br>
        Max allowed size: 40560 KB
      </ngx-dropzone-label>
    </ng-template>
    <ng-template  #globalBlock>
      <ngx-dropzone-label>Drop Files Here <br>
        Allowed file types: jpeg, bmp, png, pdf, doc, docx, xls, xlsx, gif, svg, mp4, qt. <br>
        Max allowed size: 40560 KB
      </ngx-dropzone-label>
    </ng-template>
  </ngx-dropzone>
</div>

