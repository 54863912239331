import {Component, OnInit} from '@angular/core';
import {AuthService} from './services/auth/auth.service';
import {AppStatusService} from './services/app-status/app-status.service';
import {Title} from "@angular/platform-browser";
import {StorageService} from "./services/storage/storage.service";
import {StorageKeys} from "./services/storage/storage-keys.enum";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'joy-world-app';
  public domain: string;

  public constructor(
    public auth: AuthService,
    public appStatus: AppStatusService,
    public titleService: Title,
    public storage: StorageService,
  ) {

  }

  public ngOnInit(): void {
    // setup application auth state on application bootstrap
    this.auth.initAuthState();
    this.appStatus.init();
    this.domain = this.storage.getItem(StorageKeys.Domain);
    if (this.domain === 'joytotheworld' || (window.location.href.indexOf("joytotheworld") > -1)) {
      this.titleService.setTitle('Joy to the world');
      this.storage.saveItem(StorageKeys.Domain, 'joytotheworld');
      document.getElementById('icon').setAttribute('href', '/assets/images/jttw-logo.png');
    }
    else if(window.location.href.indexOf("beta.admin.greatertomorrow.org") > -1){
      this.titleService.setTitle('Greater Tomorrow');
      this.storage.saveItem(StorageKeys.Domain, 'beta_greatertomorrow');

    } else if(window.location.href.indexOf("stg.greatertomorrow.org") > -1){
      this.titleService.setTitle('Greater Tomorrow');
      this.storage.saveItem(StorageKeys.Domain, 'stag_greatertomorrow');

    } else if(window.location.href.indexOf("admin.greatertomorrow.org") > -1){
      this.titleService.setTitle('Greater Tomorrow');
      this.storage.saveItem(StorageKeys.Domain, 'greatertomorrow');

    } else if(window.location.href.indexOf("beta.admin.northafricapartners.org") > -1){
      this.titleService.setTitle('North Africa Partners');
      this.storage.saveItem(StorageKeys.Domain, 'beta_northafricapartners');

    } else if(window.location.href.indexOf("stg.northafricapartners.org") > -1){
      this.titleService.setTitle('North Africa Partners');
      this.storage.saveItem(StorageKeys.Domain, 'stag_northafricapartners');

    } else if(window.location.href.indexOf("admin.northafricapartners.org") > -1){
      this.titleService.setTitle('North Africa Partners');
      this.storage.saveItem(StorageKeys.Domain, 'northafricapartners');

    } else if(window.location.href.indexOf("beta.newhorizonsfoundation.com") > -1){
      this.titleService.setTitle('Newhorizons foundation');
      this.storage.saveItem(StorageKeys.Domain, 'beta_newhorizonsfoundation');

    } else if(window.location.href.indexOf(environment.fullDomains.newHorizonAdminStg) > -1){
      this.titleService.setTitle('Newhorizons foundation');
      this.storage.saveItem(StorageKeys.Domain, 'stag_newhorizonsfoundation');

    } else{
      this.titleService.setTitle('Newhorizons foundation');
      this.storage.saveItem(StorageKeys.Domain, 'newhorizonsfoundation');
    }

  }
}
