<div class="media-gallery-container">
  <div class="d-flex flex-wrap media-wrapper scroll-area align-items-baseline">
    <div class="thumbnail position-relative" *ngFor="let item of paginatedMedias.data()"
         (click)="onSelectionChange(item)" [class.selected]="isSelected(item)">
      <div class="position-relative overflow-hidden img-wrap">
        <img *ngIf="isImage(item)" class="o-cover w-100 h-100 position-absolute" src="{{item.url}}" alt="test">
        <img *ngIf="isDocument(item)" class="o-cover w-100 h-100 position-absolute" src="assets/images/doc-file.jpg"
             alt="test">
        <img *ngIf="isXls(item)" class="w-100 h-100 o-cover position-absolute"
             src="assets/images/xls.jpg" alt="test">
        <img *ngIf="isPDF(item)" class="o-cover w-100 h-100 position-absolute" src="assets/images/pdf-file.jpg"
             alt="test">
        <img *ngIf="isVideo(item)" class="o-cover w-100 h-100 position-absolute" src="assets/images/video-file.jpg"
             alt="test">
        <div class="overlay position-absolute text-center w-100">
          <button class="p-0 bg-transparent text-danger border-0" (click)="deleteMedia(item)">
            <fa-icon [icon]="faTrashAlt"></fa-icon>
          </button>
        </div> <!--/overlay---->
      </div>

      <button class="btn-selected position-absolute float-end">
        <fa-icon [icon]="faCheck"></fa-icon>
      </button>
    </div> <!--/thumbnail-->
  </div>
  <div class="d-flex w-100 justify-content-end pagination-wrap">
    <div *ngIf="paginatedMedias.total() > itemsPerPage" class="dropdown-select mt-3 me-4">
      <select class="form-control h-100" [(ngModel)]="itemsPerPage" (change)="changeItemsPerPage()">
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div *ngIf="paginatedMedias.total() > itemsPerPage" class="mt-3 page-listing">
      <ngb-pagination [collectionSize]="paginatedMedias.total()"
                      [pageSize]="itemsPerPage"
                      [(page)]="page"
                      [rotate]="true"
                      [ellipses]="true"
                      (pageChange)="loadPage($event)"
                      [maxSize]="5">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
<button class="btn-common btn-primary mt-3" (click)="onMediaSelected()">Select</button>
