<router-outlet></router-outlet>
<ngx-spinner
 bdColor="rgba(0, 0, 0, 0.3)"
 size="medium"
 color="#fff"
 type="ball-clip-rotate"
 [fullScreen]="true">
 <p style="color: white" class="text-center"> Loading... </p>
</ngx-spinner>


